import axios from "axios";

const uris = {
  dev: "https://dev.api.doorvi.co",
  production: "https://api.doorvi.co",
  callProductionUri: "https://call.doorvi.co/",
  referUrl: "https://www.doorvi.co:4009",
  callDevUri: "https://dev.call.doorvi.co/",
};

export default axios.create({
  baseURL: `${uris.production}/api`,
});

export { uris };
