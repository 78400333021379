import { QRCodeSVG } from "qrcode.react";
import bell from "../../assets/img/bell.svg";
import bellPt from "../../assets/BellPt.svg";
import { getCountryCallingCode } from "libphonenumber-js";
import i18n from "../../i18n/config";

const QR_Bell = (props: any) => {  
  return (
    <div className="qr-wrap-bell" style={{}}>
      <p className="qr-id-bell">
        {props.countries[0] === "all"
          ? `No ${props.qr.shortId}`
          : `No ${getCountryCallingCode(props.countries[0])}-${
              props.countries.length
            }-${props.qr.shortId}`}
      </p>
      <img src={i18n.language=="pt"?bellPt:bell} className="qrbell" alt="bellimg" />
      <QRCodeSVG
        size={140}
        level="H"
        imageSettings={{
          src: require("../../assets/img/doorvi_round.jpg"),
          height: 35,
          width: 35,
          excavate: true,
        }}
        className="qrcode-bell"
        value={props.value}
      />
    </div>
  );
};

export default QR_Bell;
