import { QRCodeSVG } from "qrcode.react";
import bell from "../../assets/door-sticker.svg";
import bellBlack from "../../assets/blackBellPt.svg";
import { getCountryCallingCode } from "libphonenumber-js";
import i18n from "../../i18n/config";

const QR_BlackBell = (props: any) => {
  return (
    <div
      className="qr-wrap-bell"
      style={{ backgroundColor: "#000", height: "472.50px", width: "396px" }}
    >
      <p className="qr-id-bell">
        {props.countries[0] === "all"
          ? `No ${props.qr.shortId}`
          : `No ${getCountryCallingCode(props.countries[0])}-${
              props.countries.length
            }-${props.qr.shortId}`}
      </p>
      <img
        src={i18n.language == "pt" ? bellBlack : bell}
        className="qrbell"
        alt="bellimg"
      />
      <QRCodeSVG
        size={160}
        level="H"
        // imageSettings={{
        //   src: require("../../assets/black.png"),
        //   height: 35,
        //   width: 35,
        //   excavate: true,
        // }}
        className="qrcode-bell"
        value={props.value}
      />
    </div>
  );
};

export default QR_BlackBell;
