import React, { Component } from "react"; 
import "../styles/dashboard.css";
import { MdChangeCircle, MdOutlineQrCode } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { BiData, BiDetail, BiUser, BiUserCheck } from "react-icons/bi";
import { MdAddToHomeScreen } from "react-icons/md";
import Table from "../Table/Table";
import QRDialog from "../components/QRDialog";
import AdminAuth from "../utility/auth";
import { deleteAgentApi, fetchOrders, fetchVirtualOrders } from "../api";
import Loader from "../components/Loader";
import { CircularProgress, Dialog } from "@mui/material";
import QrData from "../components/QrData";
import EventEmitter from "events";
import UserInfo from "../components/UserInfo";
import QrDeactivate from "../components/QrDeactivate";
import UserSignup from "./UserSignup";
import VirtualOrderdetails from "../Table/VirtualOrder/VirtualOrderdetails";
import SampleOtp from "../components/SampleOtp";
import CheckServerStatus from "../components/CheckServerStatus";
import Subsription from "../components/Subsription";
import AddAgent from "./AddAgent";
import EditAgent from "../components/EditAgent";
import AgentTable from "../Table/AgentData/Table";
import Swal from "sweetalert2";
import ReferTable from "../Table/refer/ReferTable";
import EditWithdraw from "../components/EditWithdraw";
import ReferUserTable from "../Table/ReferuserData/Table";
import ChangeSubscription from "../components/ChangeSubscription";
import QrDataByDbId from "../components/QrDataByDbId";

const em = new EventEmitter();
const MenuItem = (props: any) => {
  return (
    <div className="item-wrap">
      <div className="left-menu_item" onClick={props.onClick}>
        <props.Icon />
        <p>{props.text}</p>
      </div>
    </div>
  );
};

interface DashBoardState {
  qrDialogOpen: boolean;
  loading: boolean;
  showEdit: boolean;
  showEditRefer: boolean;
  orders: any;
  virtualOrders: any;
  index: number;
  pageLoading:boolean;
}
interface DashBoardProps {
  history: any;
}

export default class DashBoard extends Component<
  DashBoardProps,
  DashBoardState
> {
  private editAgentData: any;
  private editReferData: any;
  constructor(props: DashBoardProps) {
    super(props);
    this.state = {
      qrDialogOpen: false,
      showEdit: false,
      showEditRefer: false,
      orders: [],
      virtualOrders: [],
      loading: false,
      index: AdminAuth.getInstance()?.userData?.agentCode === "Admin" ? 122 : 3,
      pageLoading:true,
    };
    this.editAgentData = undefined;
    this.editReferData = undefined;
  }

  fetchOrdersData = async () => {
    this.setState({ loading: true });
    const data = await fetchOrders();
    this.setState({ orders: data, loading: false });
  };
  fetchVirtualOrdersData = async () => {
    this.setState({ loading: true });
    const data = await fetchVirtualOrders();
    const modData: any = [];
    data.forEach((order: any) => {
      modData.push({ ...order, ...order.purchasedBy, purchasedBy: undefined });
    });
    console.log("mod", modData);

    this.setState({ virtualOrders: modData.reverse(), loading: false });
  };

  async componentDidMount() {
    if (!AdminAuth.getInstance().getAuth()) {
      window.location.href = "/";
    } else {
      AdminAuth.getInstance().eventEmitter.addListener(
        "showEdit",
        (data: any) => {
          this.setState({ showEdit: true });
          this.editAgentData = data;
        }
      );
      AdminAuth.getInstance().eventEmitter.addListener(
        "showEditRefer",
        (data: any) => {
          this.setState({ showEditRefer: true });
          this.editReferData = data;
        }
      );
      AdminAuth.getInstance().eventEmitter.addListener(
        "showDelete",
        (data: any) => {
          this.editAgentData = data;
          this.handleDeleteAgent();
        }
      );
    }
    this.setState({pageLoading:false});
  }

  handleDeleteAgent = async () => {
    Swal.fire({
      title: "Do you want to delete agent?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancel`,
    }).then(async (result:any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const res = await deleteAgentApi(this.editAgentData._id);
        if (res?.isSuccess) {
          AdminAuth.getInstance().eventEmitter.emit("refreshAgentData", "");
        }
      }
    });
  };

  handleOrderDetail = async () => {
    this.setState({ index: 2 });
    await this.fetchOrdersData();
  };
  handleVirtualOrderDetail = async () => {
    this.setState({ index: 6 });
    await this.fetchVirtualOrdersData();
  };
  handleUserData = async () => {
    if (this.state.index !== 3) {
      this.setState({ index: 3 });
    } else {
      em.emit("onRefresh", "");
    }
  };
  handleAFUserData = async () => {
    if (this.state.index !== 8) {
      this.setState({ index: 8 });
    } else {
      em.emit("onRefresh", "");
    }
  };
  handleagentData = async () => {
    if (this.state.index !== 11) {
      this.setState({ index: 11 });
    } else {
      // AdminAuth.getInstance().eventEmitter.emit("refreshAgentData", "");
    }
  };

  handleOpen = () => {
    this.setState({ qrDialogOpen: true });
  };
  handleClose = () => {
    this.setState({ qrDialogOpen: false });
  };
  handlecloseEditAgent = () => {
    this.setState({ showEdit: false });
  };
  handlecloseEditRefer = () => {
    this.setState({ showEditRefer: false });
  };
  render() {
    if(this.state.pageLoading){
      return <Loader/>
    }
    return (
      <div
        className="container"
        style={{
          display: "flex",
        }}
      >
        <QRDialog
          handleClose={this.handleClose}
          handleOpen={this.handleOpen}
          open={this.state.qrDialogOpen}
          history={this.props.history}
        />
        <Dialog
          style={{
            fontFamily: "inherit",
          }}
          open={this.state.showEdit}
          onClose={this.handlecloseEditAgent}
        >
          <EditAgent agentData={this.editAgentData} />
        </Dialog>
        <Dialog
          style={{
            fontFamily: "inherit",
          }}
          open={this.state.showEditRefer}
          onClose={this.handlecloseEditRefer}
        >
          <EditWithdraw referData={this.editReferData} />
        </Dialog>
        <div className="left">
          <>
            <img className="left-logo" src="logo2.png" alt="logo" />
          </>
          {AdminAuth.getInstance().userData.agentType === "Admin" ? (
            <div className="left-menu">
              <MenuItem
                text="Check Server Status"
                Icon={() => <MdAddToHomeScreen size={22} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 122 });
                }}
              />
              <MenuItem
                text="Change Plan"
                Icon={() => <MdChangeCircle size={22} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 21 });
                }}
              />
              <MenuItem
                text="QR Code Data"
                Icon={() => <FaDatabase size={20} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 0 });
                }}
              />
              <MenuItem
                text="QR Code By Full ID"
                Icon={() => <FaDatabase size={20} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 132 });
                }}
              />
              <MenuItem
                text="Generate QR Code"
                onClick={this.handleOpen}
                Icon={() => <MdOutlineQrCode size={20} color="#030303" />}
              />
              <MenuItem
                text="Deactivate QR Code"
                Icon={() => (
                  <img
                    src="/qrremove.png"
                    style={{ width: 22 }}
                    alt="qrremove"
                  />
                )}
                onClick={() => {
                  this.state.index !== 4 && this.setState({ index: 4 });
                }}
              />
              <MenuItem
                text="Order Details"
                Icon={() => <BiDetail size={20} color="#030303" />}
                onClick={() => {
                  this.handleOrderDetail();
                }}
              />
              <MenuItem
                text="Virtual Order Details"
                Icon={() => <BiDetail size={20} color="#030303" />}
                onClick={() => {
                  this.handleVirtualOrderDetail();
                }}
              />
              <MenuItem
                text="Master Data"
                Icon={() => <BiUserCheck size={22} color="#030303" />}
                onClick={() => {
                  this.handleUserData();
                }}
              />
              <MenuItem
                text="Amazon/Flipkart Users"
                Icon={() => <BiUserCheck size={22} color="#030303" />}
                onClick={() => {
                  this.handleAFUserData();
                }}
              />
              <MenuItem
                text="OYO - USER"
                Icon={() => <BiUser size={22} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 5 });
                }}
              />
              <MenuItem
                text="Add Sample Otp"
                Icon={() => <MdAddToHomeScreen size={22} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 7 });
                }}
              />
              <MenuItem
                text="Referal Users"
                Icon={() => <MdAddToHomeScreen size={22} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 13 });
                }}
              />
              <MenuItem
                text="Withdraw request"
                Icon={() => <MdAddToHomeScreen size={22} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 12 });
                }}
              />
              <MenuItem
                text="Agent Data"
                Icon={() => <MdAddToHomeScreen size={22} color="#030303" />}
                onClick={this.handleagentData}
              />

              <MenuItem
                text="subsription"
                Icon={() => <MdAddToHomeScreen size={22} color="#030303" />}
                onClick={() => {
                  this.setState({ index: 9 });
                }}
              />              
            </div>
          ) : (
            <MenuItem
              text="Master Data"
              Icon={() => <BiUserCheck size={22} color="#030303" />}
              onClick={() => {
                this.handleUserData();
              }}
            />
          )}

          <div className="left-profile"></div>
        </div>
        <div className="right">
          {this.state.index === 0 && <QrData />}
          {this.state.index === 132 && <QrDataByDbId />}
          {this.state.index === 4 && <QrDeactivate />}
          {this.state.index === 5 && <UserSignup />}
          {this.state.index === 3 && <UserInfo em={em} />}
          {this.state.index === 8 && <UserInfo twoHomeApi={true} em={em} />}
          {this.state.index === 12 && <ReferTable />}
          {this.state.index === 13 && <ReferUserTable />}
          {this.state.index === 9 && <Subsription />}
          {this.state.index === 10 && <AddAgent />}
          {this.state.index === 21 && <ChangeSubscription />}
          {this.state.index === 11 && (
            <AgentTable
              addAgent={() => {
                this.setState({ index: 10 });
              }}
            />
          )}
          {this.state.index === 7 && <SampleOtp em={em} />}
          {this.state.index === 122 && <CheckServerStatus />}
          {this.state.index === 2 && (
            <>
              {this.state.orders && !this.state.loading ? (
                <Table
                  fetchOrdersData={this.fetchOrdersData}
                  orders={this.state.orders}
                />
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </>
          )}
          {this.state.index === 6 && (
            <>
              {this.state.virtualOrders && !this.state.loading ? (
                <VirtualOrderdetails
                  fetchOrdersData={this.fetchVirtualOrdersData}
                  orders={this.state.virtualOrders}
                />
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
